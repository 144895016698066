




















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { GROUP, IGroup } from '@/interface/group';
import { IProject, PROJECT } from '@/interface/project';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';
import GROUP_STATUS = GROUP.GROUP_STATUS;

@Component({
  components: { TopShortcutLeft },
})
export default class ResultReport extends Vue {
  search: string = '';
  page: number = 1;
  limitPage: number = 10;
  totalRow: number = 0;
  startDate: string = this.$moment().add('-1', 'y').format('YYYY-MM-DD');
  endDate: string = this.$moment().format('YYYY-MM-DD');
  sort: number = -1;
  PROJECT: any = PROJECT;
  GROUP: any = GROUP;
  selectProject: IProject = PROJECT.getInitProject();
  selectGroup: IGroup = GROUP.getInitGroup();
  projectArray: Array<IProject> = [];

  async created() {
    await this.projectList();
  }

  async projectList(): Promise<void> {
    try {
      const sendData = {
        page: this.page,
        limitPage: this.limitPage,
        search: this.search,
        sort: this.sort,
        startDate: this.startDate,
        endDate: this.endDate,
        status: GROUP_STATUS.COMPLETED,
        isResultPage: 'true',
      };
      const { data } = await this.axios.get(`/project`, { params: sendData });
      const { projects, total } = data;
      this.totalRow = total;
      this.projectArray = projects.map((p: any) => {
        return {
          groupShow: false,
          ...p,
        };
      });
    } catch (e) {
      console.log(e);
    }
  }

  sortChange() {
    this.sort = this.sort * -1;
    this.page = 1;
    this.projectList();
  }

  projectStatusColor(status: string): string {
    if (status == '대기') return 'warning';
    else if (status == '예정') return 'dark';
    else if (status == '진행') return 'info';
    else if (status == '완료') return 'success';
    else return `secondary`;
  }

  groupStatusColor(status: GROUP.GROUP_STATUS): string {
    if (status == GROUP.GROUP_STATUS.EXPECT) return 'dark';
    else if (status == GROUP.GROUP_STATUS.END) return 'danger';
    else if (status == GROUP.GROUP_STATUS.PROGRESS) return 'info';
    else if (status == GROUP.GROUP_STATUS.COMPLETED) return 'success';
    else return `secondary`;
  }

  calculatorGroupTime(length: number): string {
    const hour = Math.floor(length / 60);
    const hourStr = hour < 1 ? '' : `${hour}Hour `;
    const minute = length % 60;
    const minuteStr = minute === 0 ? '' : `${minute}Minute`;
    return hourStr + minuteStr;
  }

  async openGroupInfo(_id: string) {
    window.open(`/project/group-report/${_id}`);
  }

  getGroupStatus(status: GROUP.GROUP_STATUS): string {
    const find = GROUP.GROUP_OPTIONS.find((option) => status == option.value);
    return find == undefined ? '-' : find.text;
  }
}
